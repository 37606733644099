// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/products/ProgressBar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/products/ProgressBar.tsx");
  import.meta.hot.lastModified = "1727786050000";
}
// REMIX HMR END

import React from 'react';
export default function ProgressBar({
  maxValue,
  currentValue,
  color,
  infoTextClassname,
  baseUnit,
  takeRest
}) {
  const progressValue = Math.min(maxValue, Math.max(0, currentValue));
  let infoText = '';
  let className = '';
  if (currentValue >= maxValue) {
    infoText = 'Bestellmenge erreicht';
  } else if (takeRest) {
    infoText = 'Rest wird übernommen, wenn Menge nicht erreicht wurde';
    className = 'takeRest';
  } else {
    const remainingValue = maxValue - currentValue;
    const unitText = baseUnit === 'kg' ? 'kg bis zur Bestellung' : 'Stk damit 10% wegfallen';
    infoText = `Noch ${remainingValue}${unitText}`;
  }
  return <div>
      <div className="w-full">
        <div data-testid="progress-bar-container" className={`w-full h-4 rounded-full overflow-hidden ${className}`}>
          <div data-testid="progress-bar" className={`h-full ${color} transition-all rounded-full duration-500 ease-in-out`} style={{
          width: `${progressValue}%`
        }}></div>
        </div>
      </div>
      <div>
        <span className={`${infoTextClassname}`}>{infoText}</span>
      </div>
    </div>;
}
_c = ProgressBar;
var _c;
$RefreshReg$(_c, "ProgressBar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;